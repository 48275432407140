//
// map.js
// Theme module
//

const maps = document.querySelectorAll('[data-map]');
const accessToken = 'pk.eyJ1IjoianNzZWJhIiwiYSI6ImNsMWZuaXpoYTB3cWMzcXFpcTR0NGVjNGYifQ.S0HTC19EzpnlQZ6C-fi-og';

maps.forEach((map) => {
    const elementOptions = map.dataset.map ? JSON.parse(map.dataset.map) : {};

    const defaultOptions = {
        container: map,
        style: 'mapbox://styles/mapbox/light-v9',
        center: [41.34354670460324, -72.11400645814132], // starting position
        zoom: 9, // starting zoom
        scrollZoom: false,
        interactive: true,
    };

    const options = {
        ...defaultOptions,
        ...elementOptions,
    };

    // Get access token
    mapboxgl.accessToken = accessToken;

    // Init map
    new mapboxgl.Map(options);
});